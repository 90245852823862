import { ComparisonProps, Money } from '../../types'

import MoneyContext from '../modules/Money'

import Comparison from './Comparison'

const LoadingLarge = () => (
  <div className="inline-block text-3xl bg-slate-100 rounded-md animate-pulse w-28">&nbsp;</div>
)
const LoadingSmall = () => (
  <div className="inline-block text-sm bg-slate-100 rounded-md animate-pulse w-14">&nbsp;</div>
)
const formatValue = (value: Money | number | null, valueToFixed: number) =>
  typeof value === 'number'
    ? value.toFixed(valueToFixed)
    : !value
    ? 'N/A'
    : MoneyContext.format(value)

interface Props
  extends Omit<ComparisonProps, 'value' | 'comparisonValue' | 'toFixed' | 'className'> {
  value?: number | Money | null
  comparisonValue?: number | Money | null
  name: string
  valueToFixed?: number
  comparisonToFixed?: number
}
const Scorecard = ({
  name,
  value,
  comparisonValue,
  valueToFixed = 2,
  comparisonToFixed,
  ...comparisonProps
}: Props) => (
  <div className="px-4 py-5 bg-white shadow-sm border rounded-md overflow-hidden sm:p-6">
    <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
    <dd className="flex flex-wrap items-baseline justify-between mt-1">
      <div className="inline-flex flex-wrap items-baseline">
        <span className="text-3xl font-semibold text-gray-900 mr-2">
          {typeof value === 'undefined' ? <LoadingLarge /> : formatValue(value, valueToFixed)}
        </span>
        <span className="text-sm text-gray-500 whitespace-nowrap mt-0.5 mr-2">
          from{' '}
          {typeof comparisonValue === 'undefined' ? (
            <LoadingSmall />
          ) : (
            formatValue(comparisonValue, valueToFixed)
          )}
        </span>
      </div>
      {value &&
      comparisonValue &&
      !(typeof comparisonValue !== 'number' && comparisonValue.amount === 0) ? (
        <Comparison
          {...comparisonProps}
          className="font-semibold mt-1"
          value={value}
          comparisonValue={comparisonValue}
          toFixed={comparisonToFixed}
        />
      ) : null}
    </dd>
  </div>
)

export default Scorecard
