import useSession from '../../hooks/useSession'
import useFetchDealers from '../../hooks/useFetchDealers'

import Card from '@/gf/components/Card'
import Layout from '../Layout'
import H2 from '../H2'
import Invite from './Dealers/Invite'

const Dealers = () => {
  const { user } = useSession()

  const variables = { organizationId: user.organization.id }
  const dealers = useFetchDealers({ variables }).data?.dealers

  return (
    <Layout>
      <div className="space-y-4">
        <H2>Dealers</H2>
        <div className="flex gap-4">
          <div className="grow">
            <Card>
              <Card.Section>
                <div className="space-y-2">
                  {dealers?.map((d) => (
                    <div key={d.id}>{d.name}</div>
                  ))}

                  {dealers?.length === 0 && (
                    <span className="text-gray-500 italic">You have no dealers.</span>
                  )}
                </div>
              </Card.Section>
            </Card>
          </div>
          <Card title="Invite a Dealer">
            <Card.Section>
              <Invite />
            </Card.Section>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

export default Dealers
