import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import CategorySelect from '@/gf/components/CategorySelect'
import DateField from '@/gf/components/DateField'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import PriceInput from '@/gf/components/inputs/PriceV2'
import { BaseSelect } from '@/gf/components/Select'
import SourceProductImageCard from '@/gf/components/SourceProductImageCard'
import TextInput from '@/gf/components/TextInput'
import useBrands from '@/gf/hooks/useBrands'
import useCategories from '@/gf/hooks/useCategories'
import AvailabilityM from '@/gf/modules/Availability'
import Time from '@/gf/modules/Time'
import nth from 'lodash/nth'
import React from 'react'
import ReactQuill from 'react-quill'
import { useParams } from 'react-router-dom'
import useFetchProducts from '../../hooks/useFetchProducts'
import useMsgr from '../../hooks/useMsgr'
import useSession from '../../hooks/useSession'
import useUpdateProduct from '../../hooks/useUpdateProduct'
import ErrorsM from '../../modules/UpdateProduct/Errors'
import FormM from '../../modules/UpdateProduct/Form'
import { Availability, UpdateProductErrors, UpdateProductForm } from '../../types'
import H2 from '../H2'
import Layout from '../Layout'

const availabilities: Availability[] = ['in_stock', 'back_ordered', 'out_of_stock']

const Product = () => {
  const { user } = useSession()
  const brands = useBrands().data?.brands
  const msgr = useMsgr()
  const productId = (useParams<{ id: string }>() as { id: string }).id
  const [errors, setErrors] = React.useState<UpdateProductErrors>({})
  const [version, setVersion] = React.useState(0)
  const categories = useCategories().data?.categories

  const variables = {
    organizationId: user.organization.id,
    filter: JSON.stringify(['id_equals', productId]),
  }
  const result = useFetchProducts({ variables })

  const product = result.data && nth(result.data.paginatedProducts.products, 0)

  const updateProduct = useUpdateProduct()

  const [form, setForm] = React.useState<UpdateProductForm>()

  // reloads the product, then causes useEffect to re-set the form from the product
  const refresh = () => result.refetch(variables).then(() => setVersion((prev) => prev + 1))

  React.useEffect(() => {
    if (product) setForm(FormM.fromProduct(product))
  }, [!product, version])

  if (!form || !product) return null

  const onSubmit = () => {
    setErrors({})
    msgr.clear()

    updateProduct(FormM.toPayload(form))
      .then(() => {
        msgr.add('Product saved.', 'positive')
        refresh()
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors.length > 0) {
          msgr.add('Some fields are invalid.')
          setErrors(ErrorsM.fromGqlErrors(graphQLErrors))
        } else {
          msgr.addUnknownError()
        }
      })
  }

  return (
    <Layout>
      <div className="space-y-4">
        <div className="flex">
          <H2>{product.name}</H2>
          <div className="grow" />
          <Action.S type="submit" form="update-product">
            Save
          </Action.S>
        </div>
        <Form
          onSubmit={onSubmit}
          className="flex flex-wrap md:flex-nowrap gap-4"
          id="update-product"
        >
          <div className="space-y-4 basis-full md:basis-2/3">
            <Card title="Details">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Name" errorText={errors.name}>
                    <TextInput value={form.name} setValue={(name) => setForm({ ...form, name })} />
                  </Field>

                  <Field label="Short Description" errorText={errors.shortDescription}>
                    <TextInput
                      value={form.shortDescription}
                      setValue={(shortDescription) => setForm({ ...form, shortDescription })}
                    />
                  </Field>

                  <Field label="Long Description" errorText={errors.longDescription}>
                    <ReactQuill
                      className="product-form-quill"
                      value={form.longDescription}
                      onChange={(longDescription) => setForm({ ...form, longDescription })}
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Inventory">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="MPN" errorText={errors.mpn}>
                    <TextInput value={form.mpn} setValue={(mpn) => setForm({ ...form, mpn })} />
                  </Field>

                  <Field label="Alt. MPN" errorText={errors.altMpn}>
                    <TextInput
                      value={form.altMpn}
                      setValue={(altMpn) => setForm({ ...form, altMpn })}
                    />
                  </Field>

                  <Field label="SKU" errorText={errors.sku}>
                    <TextInput value={form.sku} setValue={(sku) => setForm({ ...form, sku })} />
                  </Field>
                </div>
              </Card.Section>
            </Card>
          </div>
          <div className="space-y-4 basis-full md:basis-1/3">
            <SourceProductImageCard
              product={product}
              newImage={form.image}
              setNewImage={(image) => setForm({ ...form, image })}
            />
            <Card title="Pricing">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="List Price" errorText={errors.listPrice}>
                    <PriceInput
                      price={form.listPrice}
                      onChange={(listPrice) => setForm({ ...form, listPrice: listPrice || null })}
                    />
                  </Field>

                  <Field label="Shipping Cost" errorText={errors.shippingCost}>
                    <PriceInput
                      price={form.shippingCost}
                      onChange={(shippingCost) =>
                        setForm({ ...form, shippingCost: shippingCost || null })
                      }
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Tags">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Brand">
                    <BaseSelect
                      value={form.brandId || undefined}
                      onChange={(e) => setForm({ ...form, brandId: e.target.value })}
                    >
                      {!form.brandId && <option value={undefined}>--Select--</option>}

                      {brands?.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </BaseSelect>
                  </Field>

                  <Field label="Category">
                    <CategorySelect
                      selectedId={form.categoryId}
                      onSelect={(categoryId) => setForm({ ...form, categoryId })}
                      categories={categories}
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Status">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Availability" errorText={errors.availability}>
                    <BaseSelect
                      value={form.availability}
                      setValue={(availability) => setForm({ ...form, availability })}
                    >
                      {availabilities.map((a) => (
                        <option key={a} value={a}>
                          {AvailabilityM.format(a)}
                        </option>
                      ))}
                    </BaseSelect>
                  </Field>

                  {form.availability === 'backordered' ? (
                    <>
                      <DateField
                        label="Lead Time Date"
                        defaultValue={
                          form.leadTimeDate
                            ? Time.toFormat(Time.fromISO(form.leadTimeDate), 'yyyy-MM-dd')
                            : ''
                        }
                        onChange={(e) => {
                          const leadTimeDate =
                            Time.toPayload(Time.fromFormat(e?.target.value, 'yyyy-MM-dd')) || ''
                          setForm({ ...form, leadTimeDate })
                        }}
                      />
                      <Field label="Lead Time" errorText={errors.leadTime}>
                        <TextInput
                          value={form.leadTime}
                          setValue={(leadTime) => setForm({ ...form, leadTime })}
                        />
                      </Field>
                    </>
                  ) : null}
                </div>
              </Card.Section>
            </Card>
          </div>
        </Form>
        <div className="flex">
          <div className="grow" />
          <Action.S type="submit" form="update-product">
            Save
          </Action.S>
        </div>
      </div>
    </Layout>
  )
}

export default Product
