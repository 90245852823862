import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import CategorySelect from '@/gf/components/CategorySelect'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import PriceInput from '@/gf/components/inputs/PriceV2'
import { BaseSelect } from '@/gf/components/Select'
import TextInput from '@/gf/components/TextInput'
import useBrands from '@/gf/hooks/useBrands'
import useCategories from '@/gf/hooks/useCategories'
import AvailabilityM from '@/gf/modules/Availability'
import React from 'react'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import useAddProduct from '../../hooks/useAddProduct'
import useMsgr from '../../hooks/useMsgr'
import useSession from '../../hooks/useSession'
import ErrorsM from '../../modules/AddProduct/Errors'
import FormM from '../../modules/AddProduct/Form'
import { AddProductErrors, AddProductForm, Availability } from '../../types'
import H2 from '../H2'
import Layout from '../Layout'

const availabilities: Availability[] = ['in_stock', 'back_ordered', 'out_of_stock']

const AddProduct = () => {
  const { user } = useSession()
  const navigate = useNavigate()
  const msgr = useMsgr()
  const brands = useBrands().data?.brands
  const categories = useCategories().data?.categories

  const [form, setForm] = React.useState<AddProductForm>(
    FormM.init({ organizationId: user.organization.id })
  )

  const [errors, setErrors] = React.useState<AddProductErrors>({})
  const addProduct = useAddProduct()

  const onSubmit = () => {
    setErrors({})
    msgr.clear()

    addProduct(FormM.toPayload(form))
      .then((result) => {
        msgr.add('Product added.', 'positive')
        navigate(`/products/${result.data?.addProduct}`)
      })
      .catch((err) => {
        if (err.graphQLErrors.length > 0) {
          msgr.add('Some fields are invalid.')
          setErrors(ErrorsM.fromGqlErrors(err.graphQLErrors))
        } else {
          msgr.addUnknownError()
        }
      })
  }

  return (
    <Layout>
      <div className="space-y-4">
        <div className="flex">
          <H2>Add Product</H2>
          <div className="grow" />
          <Action.S type="submit" form="add-product">
            Submit
          </Action.S>
        </div>
        <Form onSubmit={onSubmit} className="flex flex-wrap md:flex-nowrap gap-4" id="add-product">
          <div className="space-y-4 basis-full md:basis-2/3">
            <Card title="Details">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Name" errorText={errors.name}>
                    <TextInput value={form.name} setValue={(name) => setForm({ ...form, name })} />
                  </Field>

                  <Field label="Short Description" errorText={errors.shortDescription}>
                    <TextInput
                      value={form.shortDescription}
                      setValue={(shortDescription) => setForm({ ...form, shortDescription })}
                    />
                  </Field>

                  <Field label="Long Description" errorText={errors.longDescription}>
                    <ReactQuill
                      className="product-form-quill"
                      value={form.longDescription}
                      onChange={(longDescription) => setForm({ ...form, longDescription })}
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Inventory">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="MPN" errorText={errors.mpn}>
                    <TextInput value={form.mpn} setValue={(mpn) => setForm({ ...form, mpn })} />
                  </Field>

                  <Field label="Alt. MPN" errorText={errors.altMpn}>
                    <TextInput
                      value={form.altMpn}
                      setValue={(altMpn) => setForm({ ...form, altMpn })}
                    />
                  </Field>

                  <Field label="SKU" errorText={errors.sku}>
                    <TextInput value={form.sku} setValue={(sku) => setForm({ ...form, sku })} />
                  </Field>
                </div>
              </Card.Section>
            </Card>
          </div>
          <div className="space-y-4 basis-full md:basis-1/3">
            <Card title="Pricing">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="List Price" errorText={errors.listPrice}>
                    <PriceInput
                      price={form.listPrice}
                      onChange={(listPrice) => setForm({ ...form, listPrice: listPrice || null })}
                    />
                  </Field>

                  <Field label="Shipping Cost" errorText={errors.shippingCost}>
                    <PriceInput
                      price={form.shippingCost}
                      onChange={(shippingCost) =>
                        setForm({ ...form, shippingCost: shippingCost || null })
                      }
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Tags">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Brand">
                    <BaseSelect
                      value={form.brandId || undefined}
                      onChange={(e) => setForm({ ...form, brandId: e.target.value })}
                    >
                      {!form.brandId && <option value={undefined}>--Select--</option>}

                      {brands?.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </BaseSelect>
                  </Field>

                  <Field label="Category">
                    <CategorySelect
                      selectedId={form.categoryId}
                      onSelect={(categoryId) => setForm({ ...form, categoryId })}
                      categories={categories}
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>

            <Card title="Status">
              <Card.Section>
                <div className="space-y-6">
                  <Field label="Availability" errorText={errors.availability}>
                    <BaseSelect
                      value={form.availability}
                      onChange={(e) => setForm({ ...form, availability: e.target.value })}
                    >
                      {availabilities.map((a) => (
                        <option key={a} value={a}>
                          {AvailabilityM.format(a)}
                        </option>
                      ))}
                    </BaseSelect>
                  </Field>

                  <Field label="Lead Time" errorText={errors.leadTime}>
                    <TextInput
                      value={form.leadTime}
                      setValue={(leadTime) => setForm({ ...form, leadTime })}
                    />
                  </Field>
                </div>
              </Card.Section>
            </Card>
          </div>
        </Form>
        <div className="flex">
          <div className="grow" />
          <Action.S type="submit" form="add-product">
            Submit
          </Action.S>
        </div>
      </div>
    </Layout>
  )
}

export default AddProduct
