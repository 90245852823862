import fetch from '@/gf/modules/fetch'

type Result = ['ok'] | ['error', { message: string }[]]

const useImportProducts =
  () =>
  ({ file, organizationId }: { file: File; organizationId: string }) => {
    const mutation = `mutation { importProducts(organizationId: "${organizationId}", file: "file") }`

    const formData = new FormData()
    formData.append('query', mutation)
    formData.append('file', file)

    return new Promise<Result>((resolve, reject) => {
      fetch('/gql', {
        method: 'POST',
        body: formData,
      }).then((resp) => {
        if (resp.status !== 200) {
          reject()
        } else {
          resp.json().then((json) => {
            if (json.data.importProducts) resolve(['ok'])
            if (json.errors) resolve(['error', json.errors])
          })
        }
      })
    })
  }

export default useImportProducts
