import A from '@/gf/components/A'
import Link from '@/gf/components/LinkOld'
import Pagination from '@/gf/components/Pagination'
import { LinkTd, Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import usePage from '@/gf/hooks/usePage'
import Money from '@/gf/modules/Money'
import useFetchProducts from '../../hooks/useFetchProducts'
import useSession from '../../hooks/useSession'
import H2 from '../H2'
import Layout from '../Layout'

const columns = 7

const Products = () => {
  const { user } = useSession()
  const [page, setPage] = usePage()

  const variables = { organizationId: user.organization.id, page }
  const paginatedProducts = useFetchProducts({ variables }).data?.paginatedProducts

  return (
    <Layout>
      <div className="space-y-4">
        <div className="flex justify-between items-center gap-2">
          <H2>Products</H2>
          <div className="flex items-center gap-6">
            <Link to="/products/add">Add Product</Link>
            <Link to="/products/import">Import</Link>
            <A.S href={`/exports/products?organization_id=${user.organization.id}`}>Export</A.S>
          </div>
        </div>

        <div className="md:col-span-6 rounded border border-gray-300 space-y-0">
          <Table>
            <Thead>
              <tr>
                <Th>Name</Th>
                <Th>Short Description</Th>
                <Th>SKU</Th>
                <Th>MPN</Th>
                <Th>Alt. MPN</Th>
                <Th>List Price</Th>
              </tr>
            </Thead>
            <Tbody>
              {paginatedProducts?.products.map((p) => (
                <Tr key={p.id}>
                  <LinkTd pad to={`/products/${p.id}`}>
                    {p.name}
                  </LinkTd>
                  <Td>{p.shortDescription}</Td>
                  <Td>{p.sku}</Td>
                  <Td>{p.mpn}</Td>
                  <Td>{p.altMpn}</Td>
                  <Td>{p.listPrice && Money.format(p.listPrice)}</Td>
                </Tr>
              ))}

              {paginatedProducts?.products.length === 0 && (
                <Tr>
                  <Td colSpan={columns}>No source products found.</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>

        <Pagination pagination={paginatedProducts?.pagination} page={page} updatePage={setPage} />
      </div>
    </Layout>
  )
}

export default Products
