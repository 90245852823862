import rollbar from '@/gf/config/rollbar'
import ConfigContext from '../contexts/Config'

const gfBaseUrl = process.env.CANONICAL_HOST
  ? `https://${process.env.CANONICAL_HOST}`
  : 'http://local.gearflow.com:4000'

const suppliersUrl = process.env.CANONICAL_HOST
  ? `https://suppliers.${process.env.CANONICAL_HOST}`
  : 'http://suppliers.local.gearflow.com:4000'

const gqlBaseUrl = process.env.CANONICAL_HOST
  ? `https://oems.${process.env.CANONICAL_HOST}`
  : 'http://oems.local.gearflow.com:4000'

const config = { gfBaseUrl, suppliersUrl, gqlBaseUrl, rollbar }

const ConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
)

export default ConfigProvider
